import React, { useState, useEffect } from "react";
import Header from "../../components/preview/Header";
import { Footer } from "../../views/PreviewMain/Footer";
import { WhyUs } from "./../../views/PreviewMain/WhyUs";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useTranslation } from "react-i18next";

const AboutUs = () => {
  const { t } = useTranslation();
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <>
      <div className="landing-banner contact-us">
        <Header />
        <div className="d-flex align-items-center justify-content-center p-5">
          <h2 className="text-white" style={{ fontWeight: "bold" }}>
            <span className="d-flex align-items-center justify-content-center flex-column">
              {t("about_us")}
              <img src="images/preview/line02.svg" alt="feature" />
            </span>
          </h2>
        </div>
      </div>
      <div className="about-us-second-hero py-5 bg-white">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-lg-6 d-flex align-items-center justify-content-center">
              <div className="d-flex flex-column align-items-center justify-content-center title">
                <h2>
                  <span>{t("nos_podemos_ajudar_crescer")}</span>
                  <img src="images/preview/line02.svg" alt="feature" />
                </h2>
                <p>{t("nos_podemos_ajudar_crescer_description")}</p>
              </div>
            </div>
            <div
              className={`col-sm-12 col-lg-6 d-flex align-items-center justify-content-center team-section-one ${
                width <= 991 ? "mt-20" : ""
              }`}
            >
              <Tabs>
                <TabList className="controls d-flex align-items-center justify-content-center mb-100 md-mb-60">
                  <Tab>
                    <button type="button" className="control">
                      {t("nossa_historia")}
                    </button>
                  </Tab>
                  <Tab>
                    <button type="button" className="control">
                      {t("missao")}
                    </button>
                  </Tab>
                  <Tab>
                    <button type="button" className="control">
                      {t("visao")}
                    </button>
                  </Tab>
                </TabList>
                <TabPanel>
                  <p>{t("nossa_historia_description1")}</p>
                  <p>{t("nossa_historia_description2")}</p>
                </TabPanel>
                <TabPanel>{t("missao_description")}</TabPanel>
                <TabPanel>{t("visao_description")}</TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </div>

      <WhyUs />
      <Footer />
    </>
  );
};

export default AboutUs;
