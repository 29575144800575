import React from 'react'
import { useTranslation } from "react-i18next"

export const IndustriesWeServe = () => {
  const { t } = useTranslation()
  const TechnicalToolsItems = [
    {
      icon: "technology",
      title: t("tecnologia"),
      subtitle: t("tecnologia_description"),
      animDelay: "",
    },
    {
      icon: "retail",
      title: t("varejo"),
      subtitle: t("varejo_description"),
      animDelay: "400",
    },
    {
      icon: "finance",
      title: t("financas"),
      subtitle: t("financas_description"),
      animDelay: "600",
    },
    {
      icon: "marketing",
      title: t("marketing"),
      subtitle: t("marketing_description"),
      animDelay: "600",
    },
    {
      icon: "logistic",
      title: t("logistica"),
      subtitle: t("logistica_description"),
      animDelay: "",
    },
    {
      icon: "health-care",
      title: t("saude"),
      subtitle: t("saude_description"),
      animDelay: "200",
    },
    {
      icon: "manufacturing",
      title: t("manufatura"),
      subtitle: t("manufatura_description"),
      animDelay: "400",
    },
    {
      icon: "insurance",
      title: t("seguros"),
      subtitle: t("seguros_description"),
      animDelay: "600",
    },
  ];


  return (
    <div className="home-demo-section theme-feature industries-we-serve pt-100" id="industries">
      <div className="container">
        <div className="main-title text-center">
          <h2>
            <span>
              {t("segmentos_atendemos")}
              <img src="images/preview/line02.svg" alt="feature" />
            </span>
          </h2>
        </div>
        <div className="main-wrapper pt-60">
          <div className="row justify-content-center">
            {TechnicalToolsItems.map((val, i) => (
              <div
                className="col-lg-3 col-md-4 col-sm-6 d-flex mt-30"
                key={i}
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay={val.animDelay}
              >
                <div className="feature-block">
                  <div className="d-flex align-items-center">
                    <img className="icon pr-3" src={`images/home/industriesweserve/${val.icon}.svg`} alt={val.title} /> 
                    <p className="font-rubik"><b>{val.title}</b></p>
                  </div>
                  <div className="d-flex flex-column pt-20">
                    <p className="font-rubik subtitle"><b>{val.subtitle}</b></p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center w-100 mt-20">
        <a className="button3" href="/contact-us">
          { t("contato")}
        </a>
      </div>
    </div>
  )
}
