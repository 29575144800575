import React, { useState, useEffect } from "react";
import { Link, useLocation, useHistory } from  "react-router-dom"
import Scrollspy from "react-scrollspy";
import { useTranslation } from "react-i18next"
import i18n from './../../i18nInit';
import { withNamespaces } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { isMobile } from 'react-device-detect';
import { menu_header } from '../../mocks/app.json'

const Header = () => {
  const { t } = useTranslation()
  const location = useLocation();
  const history = useHistory()
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const [navbar, setNavbar] = useState(false);
  const [languageDropdown, setLanguageDropdown] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 68) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  const path = window?.location?.hash?.length > 0 ? window?.location?.hash : window?.location?.pathname

  const [width, setWidth] = useState(window.innerWidth);
  const [pathActive, setPathActive] = useState(path);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    // console.log(i18n.resolvedLanguage)
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  useEffect(() => {
    setPathActive(path)
  }, [path])
  

  // console.log(pathActive);

  const changeLanguage = (lng = false) => {
    return () => {
      if(i18n.resolvedLanguage === "en") {
        if (lng && lng === "en") {
          return;
        } else {
          i18n.changeLanguage("pt");
        }
      } else {
        if (lng && lng === "pt") {
          return;
        } else {
          i18n.changeLanguage("en");
        }
      }
    }
  }

  function openDropdown() {
    setLanguageDropdown(!languageDropdown)
  }

  return (
    <div className={navbar ? "landing-menu fixed" : "landing-menu"}>
      <div className="container-fluid">
        <div className="d-flex align-items-center justify-content-between">
          <div className="logo order-md-1">
            <Link to="/" className="d-flex align-items-center">
              <img src="/images/logo/makes.svg" alt="Makes Home" />
            </Link>
          </div>
          {/* End logo */}

          <div className="order-md-3 d-flex align-items-center">
            { !isMobile && (
              <div className="d-flex align-items-center">
                <div className="btn-language-container">
                  <div className="btn-language" onClick={openDropdown}>
                    <img src={`/images/icon/${i18n.resolvedLanguage === "en" ? "usa.png" : "brazil.png"}`} alt="language" />
                    <div className="btn-language-text">
                      { i18n.resolvedLanguage === "en" ? "EN" : "PT"}
                      <FaChevronDown className="icon" />
                    </div>
                  </div>
                  { languageDropdown && (
                    <OutsideClickHandler
                      onOutsideClick={() => {
                        setLanguageDropdown(false)
                      }}
                    >
                      <div className="btn-language-dropdown">
                        <div className="d-flex align-items-center btn-language-dropdown-item btn-language-dropdown-item-first">
                          <img src={`/images/icon/${i18n.resolvedLanguage === "en" ? "usa.png" : "brazil.png"}`} alt="language"/>
                          <div className="btn-language-text">
                            { i18n.resolvedLanguage === "en" ? "EN" : "PT"}
                            <FaChevronUp className="icon" />
                          </div>
                        </div>
                        <div className="btn-language-divider" />
                        <div className="d-flex align-items-center btn-language-dropdown-item cursor-pointer" onClick={changeLanguage()}> 
                          <img src={`/images/icon/${i18n.resolvedLanguage === "en" ? "brazil.png" : "usa.png"}`} alt="language"/>
                          <p>{ i18n.resolvedLanguage === "en" ? "PT" : "EN"}</p>
                        </div>
                      </div>
                    </OutsideClickHandler>
                  )}
                </div>
                <a
                  href="https://dmp.makes.ai/login"
                  className="menu-btn buy-button font-rubik d-none-mobile"
                  target={"_blank"}
                  style={{cursor: "pointer"}}

                >
                  {t('Fazer Login')}
                </a>
              </div>
            )}
           
            <div className="mob-header">
              <button className="toggler-menu" onClick={handleClick}>
                <div className={click ? "active" : ""}>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </button>
            </div>
            {/* End Header */}
          </div>
          {/* End div */}

          <nav
            id="feature-menu"
            className="landing-mobile_menu navbar navbar-expand-sm order-md-2 "
          >
            <div className="m-auto nav-container">
              <div id="navbarSupportedContent">
                <Scrollspy
                  className="navbar-nav"
                  items={[
                    "intro",
                    "solutions",
                    "tools",
                    "industries",
                    "benefits",
                  ]}
                  currentClassName="active"
                  offset={-65}
                >
                  {menu_header.map(({ slug, id_translation }) => (
                    <li id={id_translation} className="nav-item">
                      {(id_translation === "sobre_nos" || id_translation === "contato" || id_translation === "home")
                        ? (
                          <Link to={slug} className={`nav-link ${pathActive === slug && "bar-active"}`}>
                            {t(id_translation)}
                          </Link>
                        ) : (
                          <a href={slug} className={`nav-link ${pathActive === slug.slice(1) && "bar-active"}`}>
                            {t(id_translation)}
                          </a>
                        )
                      }
                    </li>
                  ))}
                </Scrollspy>
              </div>
            </div>
          </nav>
          {/* #feature-menu */}

          {/* Mobile Menu Start */}
          <div className={click ? "mobile-menu menu-open" : "mobile-menu"}>
            <div className="logo order-md-1">
              <a href="#intro">
                <img src="/images/logo/makes-neg.svg" alt="home" />
              </a>
              <div className="fix-icon text-dark" onClick={handleClick}>
                <img src="images/icon/close.svg" alt="icon" />
              </div>
              {/* Mobile Menu close icon */}
            </div>

            <Scrollspy
              className="navbar-nav"
              items={[
                "intro",
                "tools",
                "industries",
                "whyus",
                "benefits",
              ]}
              currentClassName="active"
              offset={-200}
            >
              {menu_header.map(({ slug, id_translation }) => (
                <li id={id_translation} className="nav-item">
                  {(id_translation === "sobre_nos" || id_translation === "contato" || id_translation === "home")
                    ? (
                      <Link to={slug} className="nav-link">
                        {t(id_translation)}
                      </Link>
                    ) : (
                      <a href={slug} className="nav-link" onClick={handleClick}>
                        {t(id_translation)}
                      </a>
                    )
                  }
                </li>
              ))}
              
              <li className="nav-item">
                <div className="btn-language-container-mobile">
                  <div className="btn-language-container-mobile-item" onClick={changeLanguage("en")}>
                    <div className="btn-language-container-mobile-item-img">
                      <img src={`/images/icon/usa.png`} alt="language" />
                      { i18n.resolvedLanguage === "en" && (
                        <div className="img-disabled" />
                      )}
                    </div>
                    <div className="btn-language-text-mobile">
                      EN
                    </div>
                  </div>
                  <div className="btn-language-container-mobile-item-divider" />
                  <div className="btn-language-container-mobile-item" onClick={changeLanguage("pt")}>
                    <div className="btn-language-container-mobile-item-img">
                      <img src={`/images/icon/brazil.png`} alt="language" />
                      { i18n.resolvedLanguage === "pt" && (
                        <div className="img-disabled" />
                      )}
                    </div>
                    <div className="btn-language-text-mobile">
                      PT
                    </div>
                  </div>
                </div>
              </li>
            </Scrollspy>
          </div>
          {/* Mobile Menu End */}
        </div>
      </div>
    </div>
  );
};

export default Header;
