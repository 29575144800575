import React, { useState, useEffect } from 'react'
import Header from "../../components/preview/Header";
import Lottie from 'react-lottie';
import animationData from "./charts.json";
import { clients } from '../../mocks/app.json'
import { Link } from "react-router-dom"
import { Carousel } from 'react-responsive-carousel';
import { useTranslation } from "react-i18next"

export const Hero = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const { t } = useTranslation()

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  function handleSizeLogo(name) {
    const arrayNamesLess = ['Edoo', 'Casa do Pica Pau', 'Sagres']

    if (arrayNamesLess.filter(i => i === name).length > 0) {
      return 100
    }else if (name === 'John deere') {
      return 200
    }else {
      return 170
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  return (
    <div className="landing-banner makes-hero" id="intro">
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-lg-6 d-flex flex-column">
            <div className="row">
              <h1 className="makes-hero--title1">
                <span>
                  { t("transforme_seu_negocio_com_ia") }
                  <img src="images/preview/line02.svg" alt="feature" />
                </span>
              </h1>
              <h1 className="makes-hero--title mt-4"> { t("aumente_suas_vendas_melhore_resultados_reduza_custos") } </h1>
              <Link className="btn-yellow mt-4" to="/contact-us">{ t("contato") }</Link>
            </div>
          </div>
          <div className="col-xs-12 col-lg-6 d-flex align-items-center justify-content-center">
            <Lottie options={{
                loop: true,
                autoplay: true, 
                animationData: animationData,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice'
                }
              }}
              height={width >= 911 ? 400 : 350}
              width={width >= 911 ? 400 : 300}
            />
          </div>
        </div>
        <hr className="divider-home" /> 
        { width >= 991 ? (
          <>
            <p style={{textAlign: 'center'}} className="mb-4 our-client-title">{ t("alguns_de_nossos_clientes")} </p>
            <Carousel
              infiniteLoop={true}
              autoPlay={true}
              showThumbs={false}
              showIndicators={false}
              showStatus={false}
            >
              <div style={{gap: 40}} className="d-flex justify-content-center align-items-center h-100">
                {clients.slice(0, 6).map(item => (
                  <div style={{ width: handleSizeLogo(item.name) }} className="">
                    <img src={item.logo} alt={item.name} />
                  </div>
                ))}
              </div>
              <div style={{gap: 40}} className="d-flex justify-content-center align-items-center h-100">
                {clients.slice(6, 12).map(item => (
                  <div style={{ width: handleSizeLogo(item.name) }} className="">
                    <img src={item.logo} alt={item.name} />
                  </div>
                ))}
              </div>
            </Carousel>
          </>
        ) : (
          <Carousel
            infiniteLoop={true}
            autoPlay={true}
            showThumbs={false}
            showIndicators={false}
            showStatus={false}
          >
            {clients.slice(0).map(item => (
              <div style={{height: '100%'}} className="d-flex justify-content-center align-items-center">
                <div style={{width: 150}}>
                  <img src={item.logo} alt={item.name} />
                </div>
              </div>
            ))}
          </Carousel>
        )}
      </div>
    </div>
    
  )
}
