// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./gordita_medium-webfont.woff2";
import ___CSS_LOADER_URL_IMPORT_1___ from "./gordita_medium-webfont.woff";
import ___CSS_LOADER_URL_IMPORT_2___ from "./gordita_regular-webfont.woff2";
import ___CSS_LOADER_URL_IMPORT_3___ from "./gordita_regular-webfont.woff";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on June 23, 2021 */\n@font-face {\n  font-family: \"gordita\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\");\n  font-weight: 500;\n  font-style: normal; }\n\n@font-face {\n  font-family: \"gordita\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\");\n  font-weight: normal;\n  font-style: normal; }\n", "",{"version":3,"sources":["webpack://src/assets/fonts/gordita/stylesheet.css"],"names":[],"mappings":"AAAA,gFAAA;AAEA;EACE,sBAAsB;EACtB,oHACmD;EACnD,gBAAgB;EAChB,kBAAkB,EAAA;;AAGpB;EACE,sBAAsB;EACtB,oHACoD;EACpD,mBAAmB;EACnB,kBAAkB,EAAA","sourcesContent":["/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on June 23, 2021 */\n\n@font-face {\n  font-family: \"gordita\";\n  src: url(\"gordita_medium-webfont.woff2\") format(\"woff2\"),\n    url(\"gordita_medium-webfont.woff\") format(\"woff\");\n  font-weight: 500;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: \"gordita\";\n  src: url(\"gordita_regular-webfont.woff2\") format(\"woff2\"),\n    url(\"gordita_regular-webfont.woff\") format(\"woff\");\n  font-weight: normal;\n  font-style: normal;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
