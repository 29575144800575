import React from 'react'
import { FaTiktok, FaFacebookF, FaLinkedinIn, FaInstagram, FaPinterest } from 'react-icons/fa'

function SocialMedia() {
  return (
    <div style={{ display: 'flex', alignItems: "center", gap: 14, fontSize: 16, color: "#406ADF" }}>
        <a href='https://www.linkedin.com/company/makesai' target="_blank">
            <FaLinkedinIn />
        </a>
        <a href='https://web.facebook.com/makes.technology' target="_blank">
            <FaFacebookF />
        </a>
        <a href='https://www.instagram.com/makes.ai/' target="_blank">
            <FaInstagram />
        </a>
        <a href='https://www.tiktok.com/@makes.ai' target="_blank">
            <FaTiktok />
        </a>
        <a href='https://pin.it/3hZOWVI' target="_blank">
            <FaPinterest />
        </a>
    </div>
  )
}

export default SocialMedia