import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SocialMedia from "../../components/contact/social-media";
import { IoLocationSharp } from "react-icons/io5";

export const Footer = () => {
  const location = useLocation();
  const [width, setWidth] = useState(window.innerWidth);
  const { t } = useTranslation();

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <footer className="bg-white w-100 p-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-sm-12 footer-item">
            <div className="d-flex flex-column align-items-center">
              <img src="/images/logo/makes-neg.svg" alt="Makes.ai" />
              <div className="mt-4">
                <Link className="button3" to="/contact-us">
                  {t("contato")}
                </Link>
              </div>
            </div>
          </div>
          <div
            className={`col-lg-3 col-sm-12 d-flex flex-column footer-item ${
              width <= 991
                ? "justify-content-center align-items-center mt-20"
                : ""
            }`}
          >
            <Link to="/">Home</Link>
            {location.pathname === "/" ? (
              <>
                <a href="#solutions">{t("solucoes")}</a>
                <a href="#tools">{t("ferramentas")}</a>
              </>
            ) : (
              <>
                <Link to="/#solutions">{t("solucoes")}</Link>
                <Link to="/#tools">{t("ferramentas")}</Link>
              </>
            )}
          </div>
          <div
            className={`col-lg-3 col-sm-12 d-flex flex-column footer-item ${
              width <= 991
                ? "justify-content-center align-items-center mt-10"
                : ""
            }`}
          >
            {location.pathname === "/" ? (
              <>
                <a href="#industries">{t("segmentos")}</a>
                <a href="#benefits">{t("vantagens")}</a>
              </>
            ) : (
              <>
                <Link to="/#industries">{t("segmentos")}</Link>
                <Link to="/#benefits">{t("vantagens")}</Link>
              </>
            )}
            <Link to="/about-us">{t("sobre_nos")}</Link>
          </div>
          <div
            className={`col-lg-3 col-sm-12 d-flex flex-column footer-item ${
              width <= 991
                ? "justify-content-center align-items-center mt-10"
                : ""
            }`}
          >
            <SocialMedia />

            <div
              style={{ display: "flex", alignItems: "center", marginLeft: -10 }}
            >
              <IoLocationSharp size={30} style={{ marginBottom: 5 }} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <span
                  style={{
                    color: "#000",
                    fontSize: 14,
                    fontWeight: 700,
                    marginBottom: -10,
                  }}
                >
                  W Technology & Information Ltda CNPJ:
                </span>
                <span style={{ color: "#000", fontSize: 10, marginTop: -5 }}>
                  37.078.976/0001-97
                </span>
              </div>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", marginLeft: -10 }}
            >
              <IoLocationSharp size={30} style={{ marginBottom: 5 }} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <span
                  style={{
                    color: "#000",
                    fontSize: 14,
                    fontWeight: 700,
                    marginBottom: -10,
                  }}
                >
                  Goiania - Go, Brazil.
                </span>
                <span style={{ color: "#000", fontSize: 10, marginTop: -5 }}>
                  Rua Teresina, 380. CEP: 74815-715
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
