import React, { useState, useEffect } from "react";
import Header from "../../components/preview/Header";

export default function PrivacyPolicy() {
  return (
    <>
      <div className="landing-banner" style={{ background: "#fff"}}>
        <Header />
        <div className="container py-5 privacy-policy">
          <h4>Em vigor a partir de 3 de Janeiro de 2022.</h4>
          <p>
            Quando você usa nossos serviços, está confiando a nós suas informações. Entendemos que isso é uma grande responsabilidade e trabalhamos duro para proteger essas informações e colocar você no controle.
            Esta Política de Privacidade destina-se a ajudar você a entender quais informações coletamos, por que as coletamos e como você pode atualizar, gerenciar, requisitar e excluir essas informações.
          </p>
          <p>
            Criamos uma gama de serviços que ajudam pessoas e empresas descobrir e interagir com o mundo de novas maneiras através de Inteligência Artificial e outras soluções de software. Nossos serviços incluem:
          </p>
          <ul>
            <li>
              sites, dispositivos e apps da Makes;
            </li>
            <li>
                produtos integrados a apps e sites de terceiros, soluções de chatbot, sistema de recomendação, análise financeira, análise financeira, análise de clientes, projeção de suprimentos e visão computacional.
                É possível usar nossos serviços de diversas maneiras para gerenciar a sua privacidade. Por exemplo, você pode se inscrever em uma conta de um dos nossos clientes, parceiros, ou diretamente através do site da Makes. É possível também usar alguns serviços prestados pela Makes sem fazer login ou sem criar uma conta, como pesquisar sobre conteúdos em um dos nossos websites. Além disso, existe a opção de navegar na Web de forma privada usando qualquer browser disponível no mercado em modo de navegação anônima. E em todos os nossos serviços, é possível ajustar as configurações de privacidade para controlar o que coletamos e como as informações são usadas.
                Se você tiver alguma dúvida sobre esta Política de Privacidade, entre em contato conosco através do email contact@makes.ai. 
            </li>
          </ul>
          <h4>INFORMAÇÕES COLETADAS PELA MAKES</h4>
          <p>
            Queremos que você entenda os tipos de informações que coletamos quando usa nossos serviços
            Coletamos informações para fornecer serviços melhores a todos os nossos clientes e usuários, o que inclui descobrir coisas básicas, como o idioma que você se comunica, até coisas mais complexas, como as suas preferências de consumo. As informações coletadas pela Makes e como essas informações são usadas, dependem de como você usa nossos serviços e de como gerencia os controles de privacidade.
            Quando você não está conectado a uma Conta da Makes, ou de algum cliente que é diretamente servido pelas nossas soluções de tecnologia, armazenamos as informações que coletamos com identificadores exclusivos vinculados ao navegador, aplicativo ou dispositivo que você está usando. Isso nos permite manter suas preferências em todas as sessões de navegação; por exemplo, o idioma.
            Quando você está conectado, também coletamos informações que armazenamos com sua Conta da Makes e que tratamos como informações pessoais.
          </p>
          <p>
            Itens que você cria ou nos fornece
            Ao criar uma Conta da Makes, você nos fornece informações pessoais que incluem seu nome, CPF ou CNPJ, e-mail e uma senha. Você também pode optar por adicionar um número de telefone ou informações de pagamento à sua conta. Mesmo se não estiver conectado a uma Conta de serviços da Makes, você poderá optar por nos fornecer informações, como um endereço de físico, nome de familiares, renda mensal, quantidade de colaboradores (se aplicável), produtos em estoque (se aplicável), fluxo financeiro (se aplicável), lista de clientes (se aplicável) e endereço de redes sociais, assim como preferências e hábitos, para receber atualizações sobre nossos serviços.
            Também coletamos o conteúdo que você pode eventualmente criar ou que recebe de outras pessoas ao usar nossos serviços. Isso inclui e-mails enviados e recebidos, fotos e vídeos salvos, documentos e planilhas criados e comentários feitos em nossas plataformas.
            Informações que coletamos quando você usa nossos serviços
            Seus apps, navegadores e dispositivos
            Coletamos informações sobre os apps, navegadores e dispositivos que você usa para acessar os serviços prestados pela Makes.
            As informações que coletamos incluem identificadores exclusivos, tipo e configurações de navegador, tipo e configurações de dispositivo, sistema operacional, informações de rede móvel, incluindo nome e número de telefone da operadora e número da versão do aplicativo. Também coletamos informações sobre a interação de apps, navegadores e dispositivos com nossos serviços, incluindo endereço de IP, relatórios de erros, atividade do sistema, além de data, hora e URL referenciador da sua solicitação.
            Coletamos essas informações quando um serviço da Makes no seu dispositivo entra em contato com nossos servidores ou em servidores de clientes ou parceiros que utilizem nossas soluções de tecnologia. Se você estiver usando alguma solução desenvolvida pela Makes, o dispositivo entrará em contato periodicamente com os servidores da Makes para fornecer informações sobre o dispositivo e a conexão com nossos serviços. Essas informações incluem tipo de dispositivo e nome da operadora, relatórios de erros, apps instalados e, dependendo das configurações, outras informações sobre como você está usando nossas soluções tecnológicas. 
            Sua atividade
          </p>
          <h4>Política de Privacidade e Proteção de Dados</h4>
          <p>
            Coletamos informações sobre sua atividade em nossos serviços e usamos tal informação para recomendar um uma solução de que você pode gostar, por exemplo. As informações de atividades que coletamos podem incluir o seguinte:
          </p>
          <ul>
            <li>Termos que você pesquisa</li>
            <li>Textos que você lê</li>
            <li>Vídeos que você assiste</li>
            <li>Produtos e serviços que você visualiza</li>
            <li>Visualizações e interações com conteúdo e anúncios</li>
            <li>Informações de voz e áudio</li>
            <li>Atividade de compra</li>
            <li>Pessoas com quem você se comunica ou compartilha conteúdo</li>
            <li>Atividades em sites e apps de terceiros que usam nossos serviços</li>
            <li>Histórico de navegação do seu navegador que você sincronizou com algum dos nossos serviços.</li>
            <li>Textos que você lê</li>
            <li>Textos que você lê</li>

          </ul>
          <p>
            Se você usa nossos serviços para trocar mensagens, fazer ou receber mensagens, podemos coletar informações de registro de chamadas e mensagens, como nomes, o número do seu telefone, número de quem chama, número de quem recebe, números de encaminhamento, endereço de e-mail do remetente e destinatário, horário e data de chamadas e mensagens, duração das chamadas, informações de roteamento e tipos e volumes de chamadas e mensagens.
            Suas informações de localização
          </p>
          <p>
            Quando você usa nossos serviços, coletamos informações sobre sua localização, o que nos ajuda a oferecer serviços mais eficientes, como apresentar a webpage no idioma local e ou mostrar anúncios de produtos ou serviços que atendam a sua região. 
            Sua localização pode ser determinada com vários graus de precisão por:
          </p>
          <ul>
            <li>GPS e outros dados do sensor do seu dispositivo</li>
            <li>Endereço IP</li>
            <li>atividade nos Serviços da Makes, como pesquisa e lugares identificados como casa ou trabalho</li>
            <li>Informações de itens próximos do dispositivo, como pontos de acesso Wi-Fi, torres de celular e dispositivos com Bluetooth ativado</li>
          </ul>
          <p>
            Os tipos de dados de localização que coletamos e por quanto tempo os armazenamos dependem, em parte, do dispositivo e das configurações da conta. Por exemplo, é possível ativar ou desativar a localização do dispositivo que você utiliza usando o app de configurações do dispositivo. Também é possível ativar o histórico de localização caso você queira criar um mapa particular dos lugares que visita com seus dispositivos conectados. E, se a configuração "Atividade na Web e de apps" estiver ativada, suas pesquisas e outras atividades dos Serviços da Makes, que podem também incluir informações de localização, serão salvas na sua conta.
          </p>
          <p>
            Em algumas circunstâncias, a Makes também coleta informações sobre você de fontes de acesso público. Por exemplo, se seu nome aparecer em um jornal local, ou base do governo, o mecanismo de pesquisa da Makes poderá indexar esse artigo e exibi-lo para outras pessoas se elas pesquisarem pelo seu nome. Também podemos coletar informações sobre você de parceiros confiáveis, como serviços de diretório que nos fornecem informações comerciais que serão exibidas nos serviços do Makes, parceiros de marketing que nos fornecem informações sobre clientes em potencial para nossos serviços comerciais e parceiros de segurança que nos fornecem informações para proteção contra abuso. Também recebemos informações de parceiros anunciantes para oferecer serviços de publicidade e pesquisa em nome deles. 
            Usamos várias tecnologias para coletar e armazenar informações, incluindo cookies, tags de pixel, armazenamento local como armazenamento do navegador da web ou caches de dados de aplicativos, bancos de dados e registros do servidor.
          </p>
          <h4>
            POR QUE A MAKES COLETA DADOS
          </h4>
          <p>Usamos as informações que coletamos em todos nossos serviços para os fins descritos abaixo.</p>
          <p>Fornecer nossos serviços</p>
          <p>Usamos as informações para fornecer nossos serviços, como processar as mensagens enviadas e recebidas em nosso serviço de chatbot, por exemplo, para retornar resultados ou ajudar usuários a serem melhor atendidos, ou receberem uma recomendação de item ou serviço que é do interesse, assim como para aumentar o nível de segurança para empresas e clientes. </p>
          <p>Manter e melhorar nossos serviços</p>
          <p>Também usamos as informações para garantir que nossos serviços funcionem como esperado, por exemplo, para rastrear interrupções ou solucionar problemas informados. E usamos as informações para aprimorar nossos serviços; por exemplo, entender quais mensagens são escritas com erros de ortografia com mais frequência nos ajuda a melhorar os recursos de verificação ortográfica usados em nossos serviços de chatbot ou para motor de recomendação. </p>
          <p>Desenvolver novos serviços</p>
          <p>Usamos as informações que coletamos nos serviços existentes no desenvolvimento de outros serviços. Por exemplo, entender quais são os itens e serviços de maior interesse de compra das pessoas ao conversar com um chatbot, para consequentemente desenvolver um sistema de análise financeira e de recomendações. </p>
          <p>Fornecer serviços personalizados, incluindo conteúdo e anúncios</p>
          <p>
            Usamos as informações que coletamos para personalizar nossos serviços, inclusive para fornecer recomendações, conteúdo e resultados de pesquisa personalizados.
          </p>
          <p>
            Dependendo das configurações, também podemos enviar anúncios personalizados com base nos seus interesses. Por exemplo, se você pesquisar "cafeteira", poderá encontrar um anúncio de marcas de café ao interagir com alguma das nossas soluções de tecnologia. É também importante saber que:
          </p>
          <ul>
            <li>Não enviamos conteúdo personalizados com base em categorias sensíveis, como raça, religião, orientação sexual ou saúde.</li>
            <li>Não compartilhamos informações que identifiquem você pessoalmente para terceiras partes, como nome ou e-mail, a menos que você nos autorize.</li>
          </ul>
          <p>Avaliar o desempenho</p>
          <p>Usamos dados para análise e avaliação a fim de entender como nossos serviços são usados. Por exemplo, analisamos dados sobre o uso das nossas soluções de IA para otimizar o design dos produtos. Também usamos dados das suas interações para ajudar as empresas e usuários a entenderem o desempenho das nossas soluções. Além das nossas próprias soluções de IA, usamos também diversas ferramentas para fazer isso, incluindo o Google Analytics e Microsoft Clarity. </p>
          <p>Entrar em contato com você</p>
          <p>Usamos as informações que coletamos, como endereço de e-mail, para interagir diretamente com você. Por exemplo, podemos enviar uma notificação se detectarmos produtos do seu interesse. Além disso, podemos informar você sobre futuras alterações ou melhorias em nossos serviços. Se você entrar em contato com a Makes, salvaremos um registro da solicitação para ajudar a resolver qualquer problema que estiver enfrentando.</p>
          <p>Proteger a Makes, nossos Clientes e o Público</p>
          <p>Usamos informações para ajudar a melhorar a segurança e confiabilidade dos nossos serviços. Isso inclui detectar, prevenir e responder a fraudes, abuso, riscos de segurança e problemas técnicos que possam prejudicar a Makes, nossos clientes ou o público em geral.</p>
          <p>Usamos diferentes tecnologias para processar suas informações para esses fins. Usamos sistemas automatizados que analisam o conteúdo para fornecer a você resultados de interação personalizadas, anúncios personalizados ou outros recursos adaptados à maneira como você usa nossos serviços. Também analisamos seu conteúdo para nos ajudar a detectar abuso como spam, malware e conteúdo ilegal. Além disso, usamos IA para reconhecer padrões nos dados. </p>
          <p>Podemos combinar as informações que coletamos com nossos serviços e nos seus dispositivos para as finalidades descritas acima. Dependendo das configurações da conta, suas atividades em outros sites e apps podem ser associadas às suas informações pessoais para melhorar os serviços fornecidos pela Makes.</p>
          <p>Se outras pessoas já tiverem o endereço de e-mail ou outra informação que identifique você, poderemos mostrar a elas suas informações da Conta do Makes que são visíveis publicamente, como o nome e a foto. Isso ajuda as pessoas a identificar uma mensagem ou e-mail recebido de você, por exemplo.</p>
          <p>Solicitaremos sua autorização antes de usar informações para fins que não estiverem abordados nesta Política de Privacidade.</p>
          
          <h4>SEUS CONTROLES DE PRIVACIDADE</h4>

          <p>Você tem escolhas em relação às informações que coletamos e como elas são usadas</p>
          <p>Esta seção descreve os principais controles para gerenciar sua privacidade em nossos serviços. Você também pode nos solicitar mais detalhes sobre a verificação sobre privacidade através do e-mail contact@makes.ai. Além dessas ferramentas, também oferecemos configurações de privacidade específicas em nossos produtos. </p>
          <p>Gerenciar, analisar e atualizar suas informações</p>
          <p>Quando você está conectado, pode analisar e atualizar informações visitando os serviços que usa. Por exemplo, nossa solução de chatbot foi projetada para identificar todas as suas preferências padrões de comunicação.</p>
          <p>Também criamos um local para armazenar as informações salvas na sua Conta do Makes.</p>
          <p>Exportar, remover e excluir informações</p>
          <p>Você pode solicitar uma cópia do conteúdo da sua Conta Makes se quiser fazer backup ou usá-lo com um serviço fora da Makes.</p>
          <p>Você também pode solicitar a remoção de conteúdo de serviços específicos do Makes com base na LGPD.</p>
          <p>Se necessário, entre em contato conosco para:</p>
          <ul>
            <li>Excluir seu conteúdo de serviços específicos da Makes;</li>
            <li>Excluir produtos específicos da Makes, incluindo as informações associadas a esses produtos;</li>
            <li>Excluir toda sua conta com a Makes. </li>
          </ul>
          <h4>COMPARTILHAMENTO DE INFORMAÇÕES</h4>
          <p>Quando a Makes compartilha as informações</p>
          <p>Não compartilhamos informações pessoais com empresas, organizações ou indivíduos externos a Makes, exceto nos casos descritos abaixo.</p>
          <p>Com sua autorização</p>
          <p>Compartilharemos informações pessoais fora da Makes quando tivermos seu consentimento. </p>
          <p>Com administradores de domínios</p>
          <p>Se você trabalha em uma organização que usa alguma plataforma como Google, Microsoft, Amazon e outros, seu administrador do domínio e os revendedores que gerenciam a conta provavelmente podem: </p>
          <ul>
            <li>visualizar estatísticas da sua conta, como quantos apps você instalou;</li>
            <li>acessar e manter informações armazenadas na sua conta, como seu e-mail;</li>
            <li>alterar a senha da sua conta;</li>
            <li>suspender ou encerrar o acesso à sua conta;</li>
            <li>receber informações da sua conta para atender qualquer legislação, regulação, ordem judicial ou solicitação governamental aplicável;</li>
            <li>restringir sua capacidade de excluir ou editar informações ou configurações de privacidade.</li>
          </ul>
          <p>Para processamento externo</p>
          <p>Fornecemos informações pessoais às nossos afiliados ou outras empresas ou pessoas confiáveis para tratar tais informações por nós, de acordo com nossas instruções e em conformidade com nossa Política de Privacidade e quaisquer outras medidas de segurança e confidencialidade adequadas. Por exemplo, usamos provedores de serviços para nos ajudar a operar nossos data centers, enviar produtos e serviços, melhorar nossos processos comerciais internos e oferecer suporte adicional a clientes e usuários. Também usamos provedores de serviços para analisar conteúdo para segurança dos usuários, além de analisar trechos de conversas salvos pelos usuários para melhorar as tecnologias de reconhecimento de texto, imagem, audio e processamento de dados da Makes.</p>
          <p>Por motivos legais</p>
          <p>Compartilharemos informações pessoais fora do Makes se acreditarmos, de boa-fé, que o acesso, o uso, a conservação ou a divulgação das informações sejam razoavelmente necessários para:</p>
          <ul>
            <li>cumprir qualquer legislação, regulação, processo legal ou solicitação governamental aplicável.</li>
            <li>cumprir Termos de Serviço aplicáveis, inclusive investigação de possíveis violações;</li>
            <li>detectar, impedir ou lidar de alguma forma com fraudes, problemas técnicos ou de segurança;</li>
            <li>proteger de prejuízos aos direitos, à propriedade ou à segurança da Makes, dos nossos usuários ou do público, conforme solicitado ou permitido por lei.</li>
          </ul>
          <p>Podemos compartilhar informações de identificação não pessoal publicamente e com nossos parceiros. Por exemplo, compartilhamos informações publicamente para mostrar performance e tendências sobre o uso geral dos nossos serviços. Também permitimos que parceiros específicos autorizados coletem informações do seu navegador ou dispositivo para fins de publicidade e medição usando os próprios cookies ou tecnologias semelhantes.</p>
          <p>Se a Makes for envolvida em uma fusão, aquisição ou venda de ativos, continuaremos a garantir a confidencialidade das suas informações pessoais e avisaremos os usuários afetados antes que as informações pessoais sejam transferidas ou submetidas a uma política de privacidade diferente.</p>
          <h4>MANTER SUAS INFORMAÇÕES SEGURAS</h4>          
          <p>Incorporamos segurança nos nossos serviços para proteger suas informações</p>
          <p>Todos os produtos da Makes são desenvolvidos com recursos de segurança sólidos que protegem continuamente suas informações. Os insights que recebemos na manutenção dos nossos serviços nos ajudam a detectar e bloquear automaticamente ameaças de segurança que chegam até você. E se detectarmos algo perigoso e digno de notificação, notificaremos e orientaremos você sobre as etapas necessárias para se manter em segurança.</p>
          <p>Trabalhamos com afinco para proteger você e a Makes de acesso, alteração, divulgação ou destruição não autorizada das informações que detemos, o que inclui:</p>
          <ul>
            <li>a utilização de criptografia para manter os seus dados privados enquanto estão em trânsito;</li>
            <li>a oferta de uma variedade de recursos de segurança, como a navegação segura, Verificação de segurança e verificação de duas etapas para ajudar você e eventuais clientes, a protegerem suas contas;</li>
            <li>a análise de nossa coleta, práticas de armazenamento e processamento de informações, o que inclui medidas de segurança física, para evitar acesso não autorizado aos nossos sistemas;</li>
            <li>	a restrição ao acesso a informações pessoais por parte de funcionários, contratados e representantes do Makes que necessitam dessas informações para processá-las. Toda pessoa com esse acesso está sujeita a rigorosas obrigações contratuais de confidencialidade, podendo ser disciplinada ou dispensada se deixar de cumprir tais obrigações.</li>
          </ul>
          <p>Se você tiver motivos para acreditar que a sua interação conosco tenha deixado de ser segura (por exemplo, se você acreditar que a segurança de qualquer conta que você possa ter conosco tenha sido comprometida), por favor, nos notifique do problema imediatamente, entrando em contato pelos meios adiante informados.</p>
          <h4>EXPORTAR E EXCLUIR INFORMAÇÕES</h4>
          <p>Você pode solicitar uma cópia das suas informações para excluí-las da sua Conta da Makes a qualquer momento.</p>
          <p>Você pode exportar uma cópia do conteúdo da Conta da Makes se quiser fazer backup ou usá-lo com um serviço fora da Makes.</p>
          <p>Para excluir suas informações ou solicitar as informações, entre em contato conosco através do e-mail contact@makes.ai.</p>
          <h4>RETENÇÃO DAS SUAS INFORMAÇÕES</h4>
          <p>Nós retemos os dados coletados por diferentes períodos, dependendo de quais dados são, de como os usamos e de como você definiu suas configurações:</p>
          <ul>
            <li>Você pode solicitação exclusão de certos dados quando quiser, como o informações que você compartilha ou faz upload. </li>
            <li>Outros dados são excluídos ou anonimizados automaticamente depois de um período.</li>
            <li>Alguns dos dados são armazenados até que você exclua sua Conta da Makes, como as informações sobre a frequência com que você usa nossos serviços.</li>
            <li>Além disso, alguns dados são armazenados por maiores períodos para fins comerciais ou legais legítimos, como segurança, prevenção de fraude e abuso ou manutenção de registros financeiros.</li>
          </ul>
          <p>Quando você solicita exclusão de dados, seguimos uma política de exclusão para garantir que eles sejam removidos de forma segura e completa dos nossos servidores ou retidos apenas de forma anônima. Nós nos esforçamos para que nossos serviços mantenham as informações protegidas em caso de exclusão acidental ou maliciosa. Devido a isso, pode haver um espaço de tempo entre o momento em que você exclui algo e o momento em que as cópias são excluídas dos nossos sistemas ativos e de backup.</p>
          <h4>COMPLIANCE E COOPERAÇÃO COM REGULAMENTADORES</h4>
          <p>Revisamos regularmente esta Política de Privacidade e nos certificamos de que processamos suas informações de formas que estão em conformidade com ela.</p>
          <p>Transferências de dados</p>
          <p>Temos servidores em diferentes locais do mundo, como Estados Unidos e Brasil, e suas informações podem ser processadas em servidores localizados fora do país em que você vive. Enquanto no Brasil estamos adequados a Lei Geral de Proteção de Dados (LGPD), as leis de proteção de dados variam dependendo do país, sendo que algumas oferecem mais proteção que outras. Independentemente do local onde suas informações são processadas, aplicamos as mesmas proteções descritas nesta política. Também atuamos em conformidade com determinadas estruturas legais relacionadas à transferência de dados.</p>
          <p>Quando recebemos reclamações formais por escrito, respondemos entrando em contato com o requerente. Trabalhamos com as autoridades reguladoras apropriadas, inclusive autoridades locais de proteção de dados, para resolver quaisquer reclamações referentes à transferência de dados que não possamos resolver diretamente com você.</p>
          <p>Requisitos do Brasil</p>
          <p>Se a legislação de proteção de dados do Brasil se aplicar ao tratamento das suas informações, forneceremos os controles descritos nesta política para que você possa exercer seu direito de:</p>
          <ul>
            <li>receber confirmação sobre o tratamento de suas informações;</li>
            <li>atualizar, corrigir, anonimizar, remover e solicitar acesso às suas informações;</li>
            <li>restringir ou se opor ao tratamento das suas informações;</li>
            <li>exportar suas informações para outro serviço.</li>
          </ul>
          <p>Para usuários e empresas localizadas no Brasil, o controlador de dados responsável por suas informações é a W Technology Ltda, a menos que indicado de outra forma em um aviso de privacidade específico do serviço. Em outras palavras, a W Technology (Makes Brasil) é responsável pelo processamento das suas informações e pelo compromisso de obedecer às leis de privacidade aplicáveis.</p>
          <p>Dados de contato</p>
          <p>Se tiver alguma dúvida, entre em contato conosco pelo email contact@makes.ai. Você também poderá entrar em contato com a autoridade local de proteção de dados se tiver dúvidas sobre seus direitos de acordo com a legislação brasileira.</p>
          <h4>SOBRE ESTA POLÍTICA</h4>
          <p>Quando esta política se aplica</p>
          <p>Esta Política de Privacidade se aplica a todos os serviços oferecidos pela Makes.ai e suas afiliadas, serviços oferecidos em plataformas de terceiros. Esta Política de Privacidade não se aplica a serviços que tenham políticas de privacidade separadas que não incorporem esta Política de Privacidade.</p>
          <p>Esta Política de Privacidade não se aplica a:</p>
          <ul>
            <li>práticas de coleta de informações de outras empresas e organizações que anunciam nossos serviços;</li>
            <li>serviços oferecidos por outras empresas ou indivíduos, inclusive produtos ou sites que podem incluir serviços da Makes aos quais a política se aplica, ou produtos ou sites que são exibidos para você nos resultados da pesquisa ou tenham links em nossos serviços.</li>
          </ul>
          <p>Alterações nesta política</p>
          <p>Alteramos esta Política de Privacidade periodicamente. Nós não reduziremos seus direitos nesta Política de Privacidade sem seu consentimento explícito. Se as alterações forem significativas, forneceremos um aviso com mais destaque, o que inclui, no caso de alguns serviços, notificação por e-mail das alterações da Política de Privacidade.</p>
          <br/>
          <p>Makes.ai</p>
          <p>Technology Solutions</p>
        </div>        
      </div>
    </>
  )
}
