import React from 'react'
import { useTranslation } from "react-i18next"

export const AIBenefits = () => {
  const { t } = useTranslation()
  const TechnicalToolsItems = [
    {
      icon: "management-planning",
      title: t("automatizacao_personalizavel"),
      animDelay: "",
    },
    {
      icon: "lightning",
      title: t("analise_cenarios"),
      animDelay: "400",
    },
    {
      icon: "career-growth",
      title: t("alta_escalabilidade"),
      animDelay: "600",
    },
    {
      icon: "growth-arrow",
      title: t("eficiencia_custo"),
      animDelay: "600",
    },
  ];
  
  return (
    <div className="home-demo-section theme-feature ai-benefits pt-100" id="benefits">
      <div className="container">
        <div className="main-title text-center">
          <h2>
            <span>
              {t("beneficios_ia")}
              <img src="images/preview/line02.svg" alt="feature" />
            </span>
          </h2>
        </div>
        <div className="main-wrapper pt-60">
          <div className="row justify-content-center">
            {TechnicalToolsItems.map((val, i) => (
              <div
                className="col-lg-3 col-md-4 col-sm-6 d-flex mt-30"
                key={i}
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay={val.animDelay}
              >
                <div className="feature-block">
                  <div className="icon d-flex justify-content-center align-items-end">
                    <img src={`images/home/aibenefits/${val.icon}.png`} alt={ val.title } />
                  </div>
                  <div className="d-flex flex-column pt-20">
                    <p className="font-rubik text-center"><b>{val.title}</b></p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center w-100 mt-20">
        <a className="button1" href="/contact-us">
          {t("contato")}
        </a>
      </div>
    </div>
  )
}
