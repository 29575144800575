import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Routes from "./router/Routes";
import ScrollToTop from "./components/ScrollToTop";
import AOS from "aos";
import "aos/dist/aos.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "./styles.css"

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Makes | AI Solutions for All Business Sizes</title>
        <meta property="og:site_name" content="Makes | AI Solutions for All Business Sizes" />
        <meta
          property="og:url"
          content="https://makes.ai"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Makes.ai"
        />
      {/*   <meta
          name="keywords"
          content="agency, bootstrap 5, business, clean, corporate, creative, fullpage, minimal, modern, multipurpose, parallax, personal, photography, portfolio, showcase, Digital marketing agency, Digital marketing company, Digital marketing services, sass, software company"
        /> */}
        <meta
          name="description"
          content="Cresça seu negócio, aumente eficiência, reduza custos e melhore resultados com nossas soluções personalizadas de Inteligência Artificial."
        />
{/*         <meta name="description" content="Portfolio & Agency React Template" />*/}
      </Helmet>
      {/* End Seo Helmt */}

      <ScrollToTop />
      <div className="main-page-wrapper p0">
        <Routes />
      </div>
    </>
  );
};

export default App;
