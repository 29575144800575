import React, { useState } from 'react'
import Header from "../../components/preview/Header";
import {Footer} from "../../views/PreviewMain/Footer";
import { useTranslation } from "react-i18next"

export const ContactUsView = () => {
  const { t } = useTranslation()
  const [letter, setLetter] = useState({
    name: "",
    phone: "",
    company: "",
    website: "",
    email: "",
    subject: "",
    message: "",
  })

  function handleChange(e) {
    setLetter({
      ...letter,
      [e.target.name]: e.target.value
    })
  }

  return (
    <>
      <div className="landing-banner contact-us">
        <Header />
        <div className="d-flex align-items-center justify-content-center p-5">
          <h2 className="text-white text-center" style={{ fontWeight: "bold" }}>
            <span>
              {t("contato")}
              <img src="images/preview/line02.svg" alt="feature" />
            </span>
          </h2>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center mt-30">
        <div className="card-contact-us">
          <img src="/images/email.png" alt="Contact Us" />
          <p className="mt-10">contact@makes.ai</p>
        </div>
      </div>
      <div className="contact-us p-5">
        <div className="container">
          <div className="row">
            {/* <div className="col-12 d-flex justify-content-center align-items-center p-4" style={{ fontWeight: "bold" }} >
              <h3>We’ll get you the help you need.</h3>
            </div> */}
            <div className="col-lg-6 col-md-12 px-3">
              <div className="input">
                <label>{t("nome_comleto")}</label>
                <input
                  name="name"
                  type="text"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 px-3">
              <div className="input pr-2">
                <label>{t("celular")}</label>
                <input
                  name="phone"
                  type="text"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 px-3">
              <div className="input pr-2">
                <label>{t("empresa")}</label>
                <input
                  name="empresa"
                  type="text"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 px-3">
              <div className="input pr-2">
                <label>{t("website")}</label>
                <input
                  name="website"
                  type="text"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 px-3">
              <div className="input pr-2">
                <label>{t("email")}</label>
                <input
                  name="email"
                  type="email"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 px-3">
              <div className="input pr-2">
                <label>{t("assunto")}</label>
                <input
                  name="subject"
                  type="text"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-lg-12 col-md-12 px-3">
              <div className="input pr-2">
                <label>{t("mensagem")}</label>
                <textarea
                  name="message"
                  type="text"
                  rows="4"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-md-12 px-3">
              <div className="d-flex align-items-center justify-content-center">
                <a 
                  className="button3 mt-30" 
                  href={`mailto:contact@makes.ai?subject=${letter.subject}&body=
                    Nome Completo: ${letter.name}
                    \n\n
                    Celular: ${letter.phone}
                    \n\n
                    Empresa: ${letter.company}
                    \n\n
                    Website: ${letter.website}
                    \n\n
                    Email: ${letter.email}
                    \n\n
                    Mensagem: ${letter.message}
                  `}>
                    { t("contato")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
