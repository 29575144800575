import React from 'react'
import Features from "../../components/preview/Features";
import { useTranslation } from "react-i18next"

export const AISolution = () => {
  const { t } = useTranslation()
  const UsefulFeatures = [
    {
      icon: "analysis-presentation",
      title: t("analise_e_conversao_de_clientes"),
      subtitle: t("analise_e_conversao_de_clientes_description"),
      animDelay: "",
    },
    {
      icon: "customer-service",
      title: t("suporte_e_retencao_de_clientes"),
      subtitle: t("suporte_e_retencao_de_clientes_description"),
      animDelay: "400",
    },
    {
      icon: "growth-presentation",
      title: t("aumento_de_vendas"),
      subtitle: t("aumento_de_vendas_description"),
      animDelay: "600",
    },
    {
      icon: "shopping-discount",
      title: t("precificacao_e_ofertas_inteligentes"),
      subtitle: t("precificacao_e_ofertas_inteligentes_description"),
      animDelay: "600",
    },
    {
      icon: "credit-card-link",
      title: t("analise_e_recuperacao_de_credito"),
      subtitle: t("analise_e_recuperacao_de_credito_description"),
      animDelay: "",
    },
    {
      icon: "checklist",
      title: t("processo_e_entregas_eficientes"),
      subtitle: t("processo_e_entregas_eficientes_description"),
      animDelay: "200",
    },
    {
      icon: "mobile-marketing-with-loudspeaker",
      title: t("analise_de_audio_imagem_texto_e_video"),
      subtitle: t("analise_de_audio_imagem_texto_e_video_description"),
      animDelay: "400",
    },
    {
      icon: "boost-productivity",
      title: t("auditoria_e_forecasting"),
      subtitle: t("auditoria_e_forecasting_description"),
      animDelay: "600",
    },
  ];

  return (
    <div className="home-demo-section theme-feature pt-100" id="solutions">
      <div className="container">
        <div className="main-title text-center">
          <h2>
            <span>
              { t("nossas_solucoes_de_ia") }
              <img src="images/preview/line02.svg" alt="feature" />
            </span>
          </h2>
        </div>
        <div className="main-wrapper pt-60">
          <div className="row justify-content-center">
            {UsefulFeatures.map((val, i) => (
              <div
                className="col-lg-3 col-md-4 col-sm-6 d-flex mt-30"
                key={i}
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay={val.animDelay}
              >
                <div className="feature-block">
                  <div className="icon d-flex justify-content-center align-items-end">
                    <img src={`images/solutions/${val.icon}.png`} alt="feature" />
                  </div>
                  <div className="d-flex flex-column pt-20">
                    <p className="font-rubik"><b>{val.title}</b></p>
                    <p className="font-rubik subtitle">{val.subtitle}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center w-100 mt-20">
        <a className="button1" href="/contact-us">
          { t("contato") }
        </a>
      </div>
    </div>
  )
}
