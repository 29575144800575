import React from 'react'
import { useTranslation } from "react-i18next"

export const WhyUs = () => {
  const { t } = useTranslation()
  const TechnicalToolsItems = [
    {
      icon: "strategy",
      title: t("solucoes_baseadas"),
      subtitle: t("solucoes_baseadas_description"),
      animDelay: "",
    },
    {
      icon: "rating",
      title: t("profissionais_alta_performance"),
      subtitle: t("profissionais_alta_performance_description"),
      animDelay: "400",
    },
    {
      icon: "nanotechnology",
      title: t("consultoria_avaliacao"),
      subtitle: t("consultoria_avaliacao_description"),
      animDelay: "600",
    },
    {
      icon: "agile",
      title: t("experiencia_com_negocios"),
      subtitle: t("experiencia_com_negocios_description"),
      animDelay: "600",
    },
    {
      icon: "collaboration",
      title: t("alinhados_lgpd"),
      subtitle: t("alinhados_lgpd_description"),
      animDelay: "",
    },
    {
      icon: "customer-support",
      title: t("suporte_especializado"),
      subtitle: t("suporte_especializado_description"),
      animDelay: "200",
    },
  ];

  return (
    <div className="theme-feature why-us pt-100" id="whyus">
      <div className="container">
        <div className="main-title text-center">
          <h2>
            <span>
              {t("porque_nos")}
              <img src="images/preview/line02.svg" alt=" Porque Nós" />
            </span>
          </h2>
        </div>
        <div className="main-wrapper pt-60">
          <div className="row justify-content-center">
            {TechnicalToolsItems.map((val, i) => (
              <div
                className="col-lg-4 col-md-4 col-sm-6 d-flex mt-30"
                key={i}
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay={val.animDelay}
              >
                <div className="feature-block">
                  <div className="d-flex flex-column align-items-center">
                    <img className="icon" src={`images/home/whyus/${val.icon}.svg`} alt={val.title} /> 
                    <p className="font-rubik pt-15 text-center m-0"><b>{val.title}</b></p>
                    <p className="font-rubik subtitle pt-15 text-center m-0"><b>{val.subtitle}</b></p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center w-100 mt-20">
        <a className="button3" href="/contact-us">
          {t("contato")}
        </a>
      </div>
    </div>
  )
}
