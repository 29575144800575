import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './assets/locale/translationsEN.json';
import translationPT from './assets/locale/translationsPT.json';

const fallbackLng = ['en'];
const availableLanguages = ['pt', 'en'];

const resources = {
  pt: {
    translation: translationPT,
  },
  en: {
    translation: translationEN,

  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    fallbackLng,
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    resources,
    detection: {
      checkWhitelist: true,
    },
    debug: true,
    whitelist: availableLanguages,
  });

export default i18n;
