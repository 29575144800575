import React from "react";
import DemoCounter from "../../components/preview/DemoCounter";
import PreviewGallery from "../../components/preview/PreviewGallery";
import Features from "../../components/preview/Features";
import WhyChoose from "../../components/preview/WhyChoose";
import WhyChooseTwo from "../../components/preview/WhyChooseTwo";
import Support from "../../components/preview/Support";
import { Helmet } from "react-helmet";
import InnerPages from "../../components/preview/InnerPages";

import { Hero } from "./Hero"
import { AISolution } from "./AISolution"
import { TechnicalTools } from "./TechnicalTools"
import { IndustriesWeServe } from "./IndustriesWeServe"
import { WhyUs} from "./WhyUs";
import { AIBenefits } from "./AIBenefits"
import { Footer } from "./Footer"

export function PreviewMain() {
  return (
    <div className="main-page-wrapper p0">
      <Hero />
      <AISolution />
      <TechnicalTools />
      <IndustriesWeServe />
      <WhyUs />
      <AIBenefits />
      <Footer />
    </div>
  );
};
