import React from "react";

// Main Preview Pages
import { PreviewMain } from "../views/PreviewMain";
import { ContactUsView } from "../views/ContactUsView/ContactUsView"
import AboutUs  from "../views/AboutUs/AboutUs"
import PrivacyPolicy from "../views/PrivacyPolicy"

// Not Found Page
import NotFound from "../views/NotFound";

// Route Specific
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollTopBehaviour from "../components/ScrollTopBehaviour";

const Routes = () => {
  return (
    <>
      <Router>
        <ScrollTopBehaviour />
        <Switch>
          <Route exact path="/" component={PreviewMain} />
          <Route exact path="/contact-us" component={ContactUsView} />
          <Route exact path="/about-us" component={AboutUs} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/404" component={NotFound} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </>
  );
};

export default Routes;
